<template>
  <SettingsEdit :settings="accountSettings" @save="accountChanged" type="account" v-if="accountSettings !== null" />
</template>

<script>
import SettingsEdit from '@/components/settings/SettingsEdit.vue'

export default {
  components: {
    SettingsEdit
  },
  created() {
    this.$store.dispatch('user/fetchData').then(user => {
      this.user = user
    })
  },
  data() {
    return {
      user: null,
      accountSettings: null
    }
  },
  watch: {
    // We need to wait until the user is loaded from API and then we can map the user settings
    // to the accountSettings object to pass it in the right form to the component
    user() {
      this.accountSettings = this.mapUserToAccountSettings()
    }
  },
  methods: {
    mapUserToAccountSettings() {
      return {
        directadv: this.user.default_directadv,
        useimprint: this.user.default_imprint,
        show_domainname: this.user.show_domainname,
        forsale_type: this.user.sale_forsale_type,
        forsale_linkstyle: this.user.sale_forsale_linkstyle,
        forsale_template: this.user.sale_forsale_template,
        custom_text: this.user.sale_custom_text,
        sellurl: this.user.sale_sellurl,
        currency: this.user.sale_currency,
        min_offer: this.user.sale_min_offer,
        imprint_type: this.user.imprint_type,
        g_imprint: this.user.g_imprint,
        g_imprint_link: this.user.g_imprint_link
      }
    },
    getSaleSettingFields(updatedAccount) {
      let saleSettings = {}
      saleSettings.sale_forsale_type = updatedAccount.forsale_type

      switch (updatedAccount.forsale_type) {
        case 'contact':
          saleSettings.sale_forsale_template = updatedAccount.forsale_template
          saleSettings.sale_forsale_linkstyle = updatedAccount.forsale_linkstyle
          saleSettings.sale_custom_text = updatedAccount.custom_text
          saleSettings.sale_min_offer = updatedAccount.min_offer
          saleSettings.sale_currency = updatedAccount.currency
          break
        case 'externallink':
          saleSettings.sale_sellurl = updatedAccount.sellurl
          saleSettings.sale_forsale_linkstyle = updatedAccount.forsale_linkstyle
          saleSettings.sale_custom_text = updatedAccount.custom_text
          break
      }

      return saleSettings
    },
    getGeneralSettingsFields(updatedAccount) {
      return {
        show_domainname: updatedAccount.show_domainname,
        default_directadv: updatedAccount.directadv,
        default_imprint: updatedAccount.useimprint,
        imprint_type: updatedAccount.imprint_type,
        g_imprint: updatedAccount.g_imprint,
        g_imprint_link: updatedAccount.g_imprint_link
      }
    },
    accountChanged(updatedAccount) {
      let data = { ...this.getSaleSettingFields(updatedAccount), ...this.getGeneralSettingsFields(updatedAccount) }

      this.$store
        .dispatch('user/saveData', data)
        .then(() => {
          this.$store.dispatch('snackbar/showSuccessMessage', { message: 'Account updated.' })
        })
        .catch(() => {
          this.$parent.$emit('api_error')
        })
    }
  }
}
</script>
